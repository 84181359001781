<template>
  <div class="news">
    <banner title="新闻资讯"/>
    <div id="selectType">
      <div id="types">
        <ul>
          <li><a target="_blank">所有新闻</a></li>
<!--          <li><a href="http://localhost:8080" target="_blank">公司新闻</a></li>-->
<!--          <li><a href="http://localhost:8080" target="_blank">行业新闻</a></li>-->
        </ul>
      </div>
    </div>
    <div class="news-section" v-loading="loading">
      <div class="news-section-content">
        <ul>
          <li v-for="data in datalist" :key="data">
            <a href="#" @click.prevent="newsClick(data.title,data.created_at,data.content)">
              <div>
                <img :src="data.coverImage" />
              </div>
              <div>
                <a href="#">
                  <p>{{data.title}}</p>
                </a>
                <p>{{data.simpleDesc}}</p>
                <span>
                    {{ new Date(data.created_at).toLocaleDateString() }}
                </span>
              </div>
            </a>
          </li>

          <div id="product-page">
            <el-pagination
                hide-on-single-page
                :current-page="listQuery.pageNum"
                :page-size="listQuery.pageCount"
                @current-change="currPageChange"
                background
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/Banner";

export default {
  name: "news",
  components: {
    Banner
  },
  data() {
    return {
      loading: true,
      newsList: [],
      newstype: 1,
      listQuery: {
        "pageNum": 1,
        "pageCount": 10,
        "type": 'news'
      },
      total: 0,
      datalist: []
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$axios({
        url:  this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.datalist = res.data.msg.articles
          this.total = res.data.msg.total
        }
        this.loading = false
      })
    },
    newsClick(title,date,content){
      localStorage.setItem("type",'news')
      localStorage.setItem("title",title)
      localStorage.setItem("date",date)
      localStorage.setItem("content",content)
      this.$router.push("/detail")
    },
    currPageChange(pageNum){
      if(pageNum!==null) {
        this.listQuery.pageNum = pageNum
        this.loadData()
      }
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    newstype(type) {
      window.console.log(type);
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.el-pagination {

  text-align: center;
}

a {
  text-decoration: none; /* 去除默认的下划线 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

.el-pagination {
  text-align: center;
}

.news {
  width: 100%;
  height: 100%;
  overflow: hidden;

  #selectType {
    width: 100%;
    height: 70px;
    background-color: white;
    //border-bottom: 1px solid #ececec;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);

    #types {
      margin: 0 auto;
      width: 1200px;
      height: 70px;

      ul {
        height: 70px;

        li {
          height: 70px;
          float: left;
          text-align: center;
          line-height: 70px;

          a {
            padding: 0 20px;
            display: block;
          //}
          //
          //a:hover, a:active {
            border-bottom: 2px solid #0068b6;
            color: #0068b6;
          }

        }
      }
    }
  }

  &-section {
    width: 100%;

    &-content {
      width: 1200px;
      //height: 1600px;
      margin: 0 auto;
      padding: 60px 0;

      ul {
        li:nth-child(1) {
          padding-top: 0;
        }

        li {
          padding: 30px 0;
          min-height: 200px;
          border-bottom-color: #ddd;
          border-bottom-width: 1px;
          border-bottom-style: dashed;

          div:nth-child(1) {
            overflow: hidden;
            display: inline-block;
            vertical-align: top;
            img {
              height: 200px;
              width: 300px;
              vertical-align: top;
              transition: transform 0.3s;
            }

            img:hover {
              transform: scale(1.1);
            }
          }

          div:nth-child(2) {
            display: inline-block;
            margin-left: 25px;
            width: 870px;

            a {
              p {
                font-size: 20px;
                line-height: 1.4em;
                //超出两行显示省略号
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                //padding-bottom: 10px;
              }
            }

            > p {
              margin: 5px 0 10px;
              color: #999999;
              line-height: 20px;
              font-family: 'Noto Sans CJK';
              font-size: 14px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }

            a:hover {
              color: #1a5eb3;
            }

            span {
              margin-top: 10px;
              font-size: 14px;
              color: rgb(146, 146, 146, 1);
            }
          }
        }
      }

      #product-page {
        margin-top: 50px;
      }
    }
  }

}
</style>
