<template>
  <div class="banner">
<!--    <div class="mm"></div>-->
    <div style="width: 100%">
      <img src="../assets/img/topimg.jpg" style="object-position: right top;"/>
    </div>
    <div class="banner-title">
      <p>{{title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    title: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: "../assets/img/topimg.jpg"
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 180px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #fff;

  .mm {
    position: absolute;
    width: 100%;
    height: 100%;

    padding: 10px;
    margin: -10px;
    filter: blur(10px);

    background-image: url(../assets/img/banner.jpg);
    background-position: center;
    background-size: 100%;
  }

  img {
    //position: absolute;
    ////width: 1240px;
    //height: 100%;
    //left: 50%;
    //transform: translate(-50%, 0);
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  &-title {
    position: absolute;
    color: #fff;
    font-size: 34px;
    font-weight: bold;
    line-height: 180px;
    text-align: center;
    width: 100%;
  }
}
</style>